<template>
    <iframe
        :src="link"
        frameborder="0"
        width="100%"
        height="100%"
        scrolling="no"
        style="background-color:#fff;"
    ></iframe>
</template>
<script>
import {getToken} from "@/libs/auth.js";
export default {
    name: "SManagerOaMeetingAttendanceScrollWrapper",
    data() {
        return {
            // jumpOri: "http://124.71.224.87:8087/web/index.html#/attendance",
            jumpOri:"https://hzjy.xinkaoyun.com:8082/web/index.html#/attendance",
            link: "",
        };
    },
    created() {
        let urlParam = this.$route.query
        console.log(this.$route,"对接就云校")
        if(urlParam.name){
            this.jumpOri=`http://124.71.224.87:8087/web/index.html#/Nailpush`
        }
        this.init();
    },
    mounted(){

    },
    methods: {
        init() {
            this.initAssignment();
        },
        initAssignment() {
            if(this.$route.query.startDate){
                this.link = this.jumpOri + `?token=${getToken()}&startDate=${this.$route.query.startDate}&endDate=${this.$route.query.endDate}`;
            }else{
                this.link = this.jumpOri + `?token=${getToken()}`;
            }
            //清空query的值
            this.$router.push({ query: {} });
            console.log(this.link, "this.link");
        },
    },
};
</script>
